import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Logo from "../../images/comis-logo.svg"
import Link from "../Link"

const Container = styled.div`
  width: 100%;
  z-index: 200;
  height: 7.4rem;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  background: ${(p) => p.theme.colors.background};
`

const HomeLink = styled(Link)`
  display: block;
  width: 10rem;

  svg {
    height: 5rem;
    margin: 1.2rem 0 0.5rem 1.2rem;
    fill: ${(p) => p.theme.colors.black};
    width: 9.3rem;
  }
`

const HeaderLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.black};
  line-height: 2.4rem;
  right: 1.2rem;
  font-size: 2rem;

  p {
    margin: 0 0.5rem;
  }
`

const Header = () => {
  const { t } = useTranslation("translations")
  const isBrowser = typeof window !== `undefined`
  const activateFreshBot = () => {
    if (isBrowser) window?.Freshbots?.activate()
  }

  return (
    <Container>
      <HomeLink title={t("Go to start page")} to="/" onClick={activateFreshBot}>
        <Logo alt="COMIS logo" />
      </HomeLink>
      <HeaderLink
        title={t("Go back to shop")}
        to="/"
        onClick={activateFreshBot}
      >
        <p>←</p>
        {t("Back to shop")}
      </HeaderLink>
    </Container>
  )
}

export default Header
