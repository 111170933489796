import React from "react"
import { useTranslation } from "react-i18next"
import styled, { ThemeProvider } from "styled-components"
import Link from "../components/Link"
import Header from "../components/checkout/Header"
import { theme } from "../theme"

import {
  MEDIA_MIN_LARGE,
  MEDIA_MIN_MEDIUM,
  containerMaxWidth
} from "../constants"

const Container = styled.div`
  max-width: ${containerMaxWidth};
  margin: 22rem auto 16rem;
  padding: 0 3rem;

  ${MEDIA_MIN_LARGE} {
    margin: 22rem auto 0;
  }
`

const Title = styled.h1`
  margin: 0;
  font-size: 10rem;
  text-align: center;
  line-height: 100%;
  font-variation-settings: "wdth" 2000, "wgth" 500;
`

const DesktopContainer = styled.div`
  display: none;

  ${MEDIA_MIN_MEDIUM} {
    display: block;

    p {
      padding: 0;
      text-align: center;
      font-size: 2rem;
      line-height: 2.4rem;
      margin: 0;
    }
  }
`

const MobileContainer = styled.div`
  p {
    font-size: 6rem;
    line-height: 9rem;
    font-variation-settings: "wdth" 1400, "wgth" 500;
    text-transform: uppercase;
    text-align: center;
  }

  ${MEDIA_MIN_MEDIUM} {
    display: none;
  }
`

const HomepageLink = styled(Link)`
  color: ${(p) => p.theme.colors.black};
  font-variation-settings: "wght" 700;
`

const Error404 = ({ pageContext }) => {
  const { t } = useTranslation("translations")

  return (
    <ThemeProvider theme={theme(pageContext.sanityTheme)}>
      <Header />
      <Container>
        <DesktopContainer>
          <Title>{t("404")}</Title>
          <p>
            {t("We were not able to find the page you are looking for.")}
            <br />
            {t("Go to our ")}{" "}
            <HomepageLink to="/">{t("homepage")}</HomepageLink>
          </p>
        </DesktopContainer>
        <MobileContainer>
          <p>{t("Page not found")}</p>
        </MobileContainer>
      </Container>
    </ThemeProvider>
  )
}

export default Error404
