import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import React, { useContext } from "react"
import { BrinkContext } from "./context/BrinkContext"

export const LocalizedLink = ({ to, ...props }) => {
  const location = useLocation()
  const { stores } = useContext(BrinkContext)

  const countries = stores.map((store) => store.countryCode.toLowerCase())

  const localizedPath = ({ path }) => {
    const pathname = location.pathname
    const parts = pathname.split("/")
    const countryCode = parts[1]
    if (countries.includes(countryCode)) {
      return `/${countryCode}${path}`
    }
    return path
  }

  return (
    <Link
      {...props}
      to={localizedPath({
        path: to
      })}
    />
  )
}
