const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : null
}

export const theme = () => {
  const primary = hexToRgb("#3C8371")

  return {
    colors: {
      background: "#ffffff",
      backgroundDark: "#010102",
      primary: `rgb(${primary})`,
      primaryLight: `rgba(${primary}, 0.1)`,
      primaryOpac: `rgba(${primary}, 0.9)`,
      black: "#000",
      blackOpac: "rgba(0, 0, 0, 0.6)",
      white: "#fff",
      whiteOpac: "rgba(255, 255, 255, 0.7);",
      success: "#03a678",
      errors: "#FF0000",
      grey: "#C4C4C4",
      lightGrey: "#e5e5e5",
      lightBorder: "#f1edea",
      mediumGrey: "#BCBCBC",
      darkGrey: "#9A9A9A;",
      disabledBackground: "rgb(154, 154, 154)",
      disabledTextColor: "#888",
      seashellOpac: "rgba(255, 255, 255, 0.5)",
      inputBackground: "#f8f8f8"
    }
  }
}
